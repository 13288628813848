import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { notification, Button } from 'antd';
import { Fetcher } from '@shared/core';
import { LOCALSTORAGE_VERSIONCONTROL } from '../../assets/constants/contants';

const StyledRefreshButton = styled(Button)`
  margin-top: 20px;
  /* height: 32px; */
  font-size: 20px;
`;

export default () => {
  const [versionFromAPI, setVersionFromAPI] = useState('');
  const [shouldRefreshForNewVersion, setShouldRefreshForNewVersion] = useState(false);

  useEffect(() => {
    const fetchVersion = async () => {
      const versionResponse = await Fetcher.get('company/getSystemSetting/version_clock');
      if (versionResponse) {
        const newestVersionFromAPI = versionResponse.value;

        let currentVersion = localStorage.getItem(LOCALSTORAGE_VERSIONCONTROL);
        if (currentVersion == undefined) {
          currentVersion = ''
        }
        if (!currentVersion || currentVersion !== newestVersionFromAPI) {
          setVersionFromAPI(newestVersionFromAPI);
          setShouldRefreshForNewVersion(true);
        }
      }
    };
    fetchVersion();
  }, []);

  useEffect(() => {
    if (shouldRefreshForNewVersion) {
      openNotification();
    } else {
      notification.destroy();
    }
  });

  const handleOnClickRefreshPage = () => {
    if (shouldRefreshForNewVersion && versionFromAPI !== '') {
      localStorage.setItem(LOCALSTORAGE_VERSIONCONTROL, versionFromAPI);
    }
    // https://stackoverflow.com/questions/2099201/javascript-hard-refresh-of-current-page
    window.location.href = window.location.href;
  };

  const openNotification = () => {
    notification.destroy();
    notification.warning({
      message: 'New version of Web Timeclock is detected. Please refresh the service!',
      description: (
        <StyledRefreshButton type="primary" size="small" onClick={handleOnClickRefreshPage}>
          refresh now
        </StyledRefreshButton>
      ),
      placement: 'bottomRight',
      duration: 0,
    });
  };

  return <></>;
};
