import React, { useState } from 'react';
import styled from 'styled-components';
const moment = require('moment-timezone');
// import Clock from 'react-live-clock';
import {
  LOCALSTORAGE_CURRENTCOMPANY,
} from '../../assets/constants/contants';
// import Menu from 'antd/lib/menu';
import {
  // Icon,
  Tooltip,
} from 'antd';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { AppContext, AppContextInterface } from '@shared/contexts/AppContext';
import Settings from '../Modal/Settings/Settings';
// import debounce from 'lodash/debounce';
// import { Fetcher } from '@shared/core';
import UrlPattern from 'url-pattern';
import logo from '../../assets/images/logomark_primary.svg';
import backIcon from '../../assets/images/go-back-left-arrow.svg';
// import UK_FLAG_ICON from '../../assets/images/united-kingdom-flag-32.png';
// import VN_FLAG_ICON from '../../assets/images/vietnam-flag-32.png';
// import DA_FLAG_ICON from '../../assets/images/denmark-flag-32.png';
import SETTINGS_ICON from '../../assets/images/SettingIconFabric.png';


import { LOCALSTORAGE_COMPANYNAME, LOCALSTORAGE_BACKURL } from '../../assets/constants/contants';

/** *******************************************************************************************************
 *                                                                                                        *
 *                                            VARIABLES                                                   *
 *                                                                                                        *
 */
const singleCompanyUrlPattern = new UrlPattern('/company(/:companyId)');
const screen_EmployeeEnterPasswordUrlPattern = new UrlPattern('/company(/:companyId)/verify(/:employeeId)');
const screen_EmployeeClockingUrlPattern = new UrlPattern('/company(/:companyId)/clocking(/:employeeId)');

// const LANGVALUES = {
//   en: <img src={UK_FLAG_ICON} alt="" />,
//   da: <img src={DA_FLAG_ICON} alt="" />,
//   vi: <img src={VN_FLAG_ICON} alt="" />,
// };

/** *******************************************************************************************************
 *                                                                                                        *
 *                                         STYLED COMPONENTS                                              *
 *                                                                                                        *
 */
// --------------------------------------------------------------------

const StyledHeader = styled.div<{ isAtHomepage: boolean }>`
  margin-bottom: 3rem;
  padding: 0 20px;
  color: #96969b;
  text-transform: uppercase;
  ${props => !props.isAtHomepage && `background-color: #edecf2;`};
  @media screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

const StyledMenu = styled.div`
  width: 100%;
  min-height: 80px;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLeftMenuItem = styled.div`
  flex-basis: 100px;
  white-space: nowrap;
  .backText {
    color: black;
    font-size: 14px;
    Display:flex;
    img {
      margin-right: 0.75rem;
      margin-top: -2px;
    }
  }
  .companyName{
    margin:0;
    color: #96969b;
    font-size: 20px;
    font-weight: bold;
}
`;
const StyledCenterMenuItem = styled.div`
  text-align: center;
  padding: 0 10px;
  p {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
  }
  time{
    color:#333;
    font-size:35px;
    font-weight:bold;
  }
`;
const StyledRightMenuItem = styled.div`
  flex-basis: 100px;
  text-align: right;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  @media screen and (max-width: 768px) {
    img {
      //width: 60%;
    }
  }
`;

/** *******************************************************************************************************
 *                                                                                                        *
 *                                         HELPER FUNCTIONS                                               *
 *                                                                                                        *
 */
async function getBackUrl() {
  // singleCompanyUrlPattern = new UrlPattern('/company(/:companyId)');
  if (singleCompanyUrlPattern.match(location.pathname)) {
    return '/';
  }

  // screen_EmployeeEnterPasswordUrlPattern = new UrlPattern("/company(/:companyId)/verify(/:employeeId)");
  if (screen_EmployeeEnterPasswordUrlPattern.match(location.pathname)) {
    const { companyId } = screen_EmployeeEnterPasswordUrlPattern.match(location.pathname);
    return `/company/${companyId}`;
  }

  // screen_EmployeeClockingUrlPattern = new UrlPattern("/company(/:companyId)/clocking(/:employeeId)");
  if (screen_EmployeeClockingUrlPattern.match(location.pathname)) {
    const { companyId } = screen_EmployeeClockingUrlPattern.match(location.pathname);
    return `/company/${companyId}`;
  }

  return null;
}

/** *******************************************************************************************************
 *                                                                                                        *
 *                                            INTERFACES                                                  *
 *                                                                                                        *
 */
interface HeaderProps extends RouteComponentProps {
  dispatch?: (action: any) => void;
  authenticated?: boolean;
}

interface HeaderStates {
  openAuthentication: boolean;
  backUrl: string;
  isOpenSetting: boolean;
}

/** *******************************************************************************************************
 *                                                                                                        *
 *                                         MAIN COMPONENT                                                 *
 *                                                                                                        *
 */
class Header extends React.PureComponent<HeaderProps, HeaderStates> {
  context: AppContextInterface;

  constructor(props: any) {
    super(props);
    this.state = {
      openAuthentication: false,
      backUrl: localStorage.getItem(LOCALSTORAGE_BACKURL),
      isOpenSetting: false,
    };
  }

  // ComponentDidMount is used to render Back button ON:
  //    1) when the Header component first mount
  //    2) when the user refreshes the page at any screen
  async componentDidMount() {
    return this.onGetBackUrl();
  }

  // ComponentDidUpdate is used to render Back button ON:
  //    1) every screen without page refreshing
  async componentDidUpdate() {
    return this.onGetBackUrl();
  }

  onGetBackUrl = async () => {
    const backUrl = await getBackUrl();
    localStorage.setItem(LOCALSTORAGE_BACKURL, backUrl);
    this.setState({ backUrl });
  };

  onSettingClicking = () => {
    this.setState(prevState => ({ isOpenSetting: !prevState.isOpenSetting }));
  };

  /** ***************************************************************************************
   *                                      RENDER                                            *
   */
  render() {
    const { backUrl } = this.state;
    const companyName = localStorage.getItem(LOCALSTORAGE_COMPANYNAME);
    const { formatMessage } = this.context.intl;
    const isAtHomepage = this.props.location.pathname === '/';
    const currentCompany = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY));
    return (
      <React.Fragment>
        <StyledHeader isAtHomepage={isAtHomepage}>
          <StyledMenu>
            <StyledLeftMenuItem>
              {this.state.backUrl && (
                <Link to={backUrl}>
                  <span className="backText">
                    <img src={backIcon} alt="backIcon" width="18" />
                    {!isAtHomepage && (
                      <p className={'companyName'}>{companyName}</p>
                    )}
                  </span>
                </Link>
              )}
            </StyledLeftMenuItem>

            <StyledCenterMenuItem>
              {isAtHomepage ? (
                <div className="brandName">
                  <img src={logo} width={70} style={{ marginTop: '20px' }} />
                </div>
              ) : (
                  <Clock format="h:mm:ss a" interval={500} timezone={!!currentCompany ? currentCompany['timezone'] : ''} />
                )}
            </StyledCenterMenuItem>
            <StyledRightMenuItem>
              {/* <Tooltip title="Clear" placement="bottom">
                <Icon type="tool" onClick={this.onClearClicking} style={{ fontSize: '25px', marginRight: '8px' }} />
              </Tooltip> */}
              {!isAtHomepage && (
                <Tooltip title="Settings" placement="bottom">
                  <div onClick={this.onSettingClicking}>
                    <img src={SETTINGS_ICON} alt="" />
                  </div>
                </Tooltip>
              )}
            </StyledRightMenuItem>
          </StyledMenu>
        </StyledHeader>

        {this.state.isOpenSetting && <Settings isOpen={this.state.isOpenSetting} onClose={this.onSettingClicking} />}
      </React.Fragment>
    );
  }
}
const Clock = (props: any) => {
  let timezone = props.timezone;
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  const [time, setTime] = useState(moment().tz(timezone).format(props.format));
  setInterval(() => {
    setTime(moment().tz(timezone).format(props.format));
  }, props.interval);
  return (
    <time className="font-bold text-5xl">{time}</time>
  );
}
Header.contextType = AppContext;
export default Object.assign(withRouter(Header), { contextType: undefined });
