import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Select } from 'antd';
import {
  LOCALSTORAGE_CURRENTCOMPANY,
  LOCALSTORAGE_SHOWDEPARTMENTS,
  LOCALSTORAGE_SHOWGROUPS,
  LOCALSTORAGE_LANGUAGES,
} from '../../../assets/constants/contants';

const { Option } = Select;

const StyledModal = styled(Modal)`
  width: 500px;
`;

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default ({ isOpen, onClose }: ModalProps) => {
  const currentCompany = JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENTCOMPANY));
  const currentCompanyId = currentCompany['company_id'];

  const [department, setDepartment] = useState(
    localStorage.getItem(`${LOCALSTORAGE_SHOWDEPARTMENTS}${currentCompanyId}`) || '-1',
  );
  const [group, setGroup] = useState(localStorage.getItem(`${LOCALSTORAGE_SHOWGROUPS}${currentCompanyId}`) || '-1');
  const [lang, setLang] = useState(localStorage.getItem(`${LOCALSTORAGE_LANGUAGES}${currentCompanyId}`) || 'en');

  const onChangeDepartment = (departmentId: string) => {
    setDepartment(departmentId);
    // The group selection must be change to default "All group" after each time the department gets changed
    setGroup('-1');
  };
  const onChangeGroup = (groupId: string) => setGroup(groupId);
  const onChangeLanguage = (key: string) => setLang(key);

  const onSubmit = () => {
    localStorage.setItem(`${LOCALSTORAGE_SHOWDEPARTMENTS}${currentCompanyId}`, department);
    if (department === '-1') {
      localStorage.setItem(`${LOCALSTORAGE_SHOWGROUPS}${currentCompanyId}`, '-1');
    } else {
      localStorage.setItem(`${LOCALSTORAGE_SHOWGROUPS}${currentCompanyId}`, group);
    }
    localStorage.setItem(`${LOCALSTORAGE_LANGUAGES}${currentCompanyId}`, lang);
    // Cookies.set(COOKIE_LANGUAGES, lang);
    // Cookies.set(`${COOKIE_LANGUAGES}${currentCompanyId}`, lang);

    // TO-DO
    //    FIND a better method to reload.
    window.location.reload(true);
    onClose();
  };

  const { departments } = currentCompany;
  const listOfDepartments = departments.map(departmentItem => (
    <Option key={departmentItem['department_id']} value={departmentItem['department_id']}>
      {departmentItem['department_name']}
    </Option>
  ));

  const listOfGroups =
    department === '-1'
      ? departments.map(departmentItem =>
          departmentItem.groups.map(groupItem => (
            <Option key={groupItem['group_id']} value={groupItem['group_id']}>
              {groupItem['group_name']}
            </Option>
          )),
        )
      : departments
          .find(departmentItem => departmentItem.department_id === department)
          .groups.map(groupItem => (
            <Option key={groupItem['group_id']} value={groupItem['group_id']}>
              {groupItem['group_name']}
            </Option>
          ));

  const isAllDepartmentsSelected = department === '-1';
  const defaultGroups = isAllDepartmentsSelected ? '-1' : group;
  return (
    <StyledModal
      className="testModal"
      title="Settings"
      visible={isOpen}
      width="350px"
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <Button className="ant-btn" key="submit" type="primary" onClick={onSubmit}>
          Save
        </Button>,
      ]}
    >
      <div>
        <p className="labelText">Show departments</p>
        <Select defaultValue={department} onChange={onChangeDepartment}>
          <Option value="-1">All departments</Option>
          {listOfDepartments}
        </Select>

        <p className="labelText">Show groups</p>
        <Select defaultValue={defaultGroups} value={group} disabled={isAllDepartmentsSelected} onChange={onChangeGroup}>
          <Option value="-1">All groups</Option>
          {listOfGroups}
        </Select>

        <p className="labelText">Languages</p>
        <Select defaultValue={lang} onChange={onChangeLanguage}>
          <Option value="en">English</Option>
          <Option value="da">Danish</Option>
          <Option value="vi">Vietnamese</Option>
        </Select>
      </div>
    </StyledModal>
  );
};
