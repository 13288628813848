import React from 'react';
import Helmet from 'react-helmet';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppContextInterface, AppContext } from '@shared/contexts/AppContext';
import { Fetcher } from '@shared/core';
import moment from 'moment';
import renderRoutes from '../../routes';
import styled from 'styled-components';
import TitanHeader from '../../components/Layouts/Header';
import { LOCALSTORAGE_COMPANYDATA } from '../../assets/constants/contants';
import UrlPattern from 'url-pattern';
import { setCurrentCompany, setCurrentEmployee, clearAppLocalStorage } from '../../utils/localStorageHelper';
// import TitanFooter from '../../components/Layouts/Footer';
import VersionComponent from '../../components/VersionComponent/Version';

const TitanLayout = styled.div`
  min-height: 100vh;
  position: relative;
  width: 100%;
  min-width: 375px;
  margin-left: auto;
  margin-right: auto;
`;
const singleCompanyUrlPattern = new UrlPattern('/company(/:companyId)');
const screen_EmployeeEnterPasswordUrlPattern = new UrlPattern('/company(/:companyId)/verify(/:employeeId)');
const screen_EmployeeClockingUrlPattern = new UrlPattern('/company(/:companyId)/clocking(/:employeeId)');

async function setCacheForScreen() {
  // singleCompanyUrlPattern = new UrlPattern('/company(/:companyId)');
  if (singleCompanyUrlPattern.match(location.pathname)) {
    const { companyId } = singleCompanyUrlPattern.match(location.pathname);
    await setCurrentCompany(companyId);
  }

  // screen_EmployeeEnterPasswordUrlPattern = new UrlPattern("/company(/:companyId)/verify(/:employeeId)");
  if (screen_EmployeeEnterPasswordUrlPattern.match(location.pathname)) {
    const { companyId, employeeId } = screen_EmployeeEnterPasswordUrlPattern.match(location.pathname);
    await setCurrentCompany(companyId);
    setCurrentEmployee(employeeId);
  }

  // screen_EmployeeClockingUrlPattern = new UrlPattern("/company(/:companyId)/clocking(/:employeeId)");
  if (screen_EmployeeClockingUrlPattern.match(location.pathname)) {
    const { companyId, employeeId } = screen_EmployeeClockingUrlPattern.match(location.pathname);
    await setCurrentCompany(companyId);
    setCurrentEmployee(employeeId);
  }
}
class App extends React.PureComponent<RouteComponentProps, { loading: boolean }> {
  context: AppContextInterface;
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    //Clear all cache

    clearAppLocalStorage();
    const ip = await Fetcher.getMyIp();

    // GET ALL COMPANIES WITH THE CORRESPONDING DATA FROM THIS IP-address
    const companies = await Fetcher.post('clockapp/checkCompanyIP', {
      ip:
        window.location.hash === '#debug'
          ? '123.25.30.82'
          : process.env.NODE_ENV === 'production'
            ? ip
            : '123.25.30.82',
      min_time: moment()
        .startOf('day')
        .unix(),
      max_time: moment()
        .endOf('day')
        .unix(),
    });
    if (companies && companies.length >= 0) {
      localStorage.setItem(LOCALSTORAGE_COMPANYDATA, JSON.stringify(companies));
      localStorage.setItem('CompanyLoaded', 'true');
     await setCacheForScreen();
    } else {
      localStorage.setItem(LOCALSTORAGE_COMPANYDATA, JSON.stringify([]));
      localStorage.setItem('CompanyLoaded', 'false');
    }
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    clearAppLocalStorage();
  }

  render() {
    const { formatMessage } = this.context.intl;
    const title = `TimeClock | ${formatMessage({ id: 'labels.slogan' })}`;

    if (this.state.loading) {
      return null;
    }

    return (
      <TitanLayout>
        <Helmet titleTemplate={'%s | TimeClock'} defaultTitle={title} meta={[]} />

        <TitanHeader />
        <VersionComponent />

        {renderRoutes()}
      </TitanLayout>
    );
  }
}

App.contextType = AppContext;
export default Object.assign(withRouter(App), { contextType: undefined });
